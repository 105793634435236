@import "./css/timeline.css";

body {
  background-color: #fff !important;
}
@font-face {
    font-family: 'MoshVerdana';
    src: url("font/MoshVerdana.ttf") format("truetype");
}
@font-face {
    font-family: 'MoshVerdana_Bold';
    src: url("font/MoshVerdana_Bold.ttf") format("truetype");
}
@font-face {
    font-family: 'MoshVerdana_Bold_Italic';
    src: url("font/MoshVerdana_Bold_Italic.ttf") format("truetype");
}
@font-face {
    font-family: 'MoshVerdana_Italic';
    src: url("font/MoshVerdana_Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'Arian-AMU';
    src: url("font/arnamu.ttf") format("truetype");
}
@font-face {
    font-family: 'Arian-AMU-Bold';
    src: url("font/arnamub.ttf") format("truetype");
}
@font-face {
    font-family: 'Arian-AMU-Bold-Italic';
    src: url("font/arnamubi.ttf") format("truetype");
}
@font-face {
    font-family: 'Arian-AMU-Italic';
    src: url("font/arnamui.ttf") format("truetype");
}
.slider div:nth-child(2) {
    display: block;
}
.MuiContainer-maxWidthLg {
    max-width: 1170px;
}

a {
    text-decoration: none;
    color: #51bce6;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    outline: none;
}

.active-image {
    height: 545px;
    visibility: visible;
    opacity: 1
}
.hidden-image {
    visibility: hidden;
    height: 0;
    opacity: 0;
}

/*Main menu*/
.topnav {
    overflow: hidden;
    background-color: #333;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: #04AA6D;
    color: white;
}

.topnav .icon {
    display: none;
}

.MuiTabs-indicator {
    display: none;
}

.MuiTabs-root button{
    color: #bbb9bd;
}
.MuiTabs-root .Mui-selected {
    color: #bbb9bd;
    font-weight: 700;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {display: none;}
    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}